import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../../layout';

import { Hero } from 'libs/growth-platform-brand-system-v2/src/templates/Hero';
import { useScreenSize } from 'libs/growth-platform-brand-system-v2/src/utils/utils';
import { PageProps } from 'gatsby';
import { morphism } from 'morphism';
import { Query } from '../../../types/dato';
import { TemplateBlocksZone } from '../../components/TemplateBlocksZone';
import { HeadWrapper } from '../../layout/HeadWrapper';
import { schemaBaseTemplate } from '../../morphismSchema/pages/schemaBaseTemplate';
import { schemaHero } from '../../morphismSchema/templates/schemaHero';

export const Head = ({ data, pageContext, location }: any) => {
  return (
    <HeadWrapper
      data={data}
      pageContext={pageContext}
      location={location}
      templateName="ClientPage"
      dataName={'datoCmsClientPage'}
    />
  );
};

export const query = graphql`
  query DatoClientPage($locale: String, $id: String) {
    datoCmsClientPage(locale: $locale, id: { eq: $id }) {
      ...ClientPage
    }
  }
`;

const ClientPage = ({ data, pageContext }: PageProps<Query>) => {
  const doc: any = data.datoCmsClientPage;

  const screenSize = useScreenSize();

  if (!doc) return null;

  const _data = morphism(schemaBaseTemplate, {
    ...doc,
    screenSize,
    pageContext,
  });
  const hero = _data.hero;

  return (
    <Layout pageContext={pageContext} data={doc} templateName="ClientPage">
      <Hero
        {...morphism(schemaHero, {
          ...hero,
          isClientHero: true,
          classnameThumbnail: 'object-cover',
          classnameBoxThumbnail: 'aspect-[16/9]',
        })}
      />

      {_data?.blocks.length > 0 && <TemplateBlocksZone {..._data} />}
    </Layout>
  );
};

export default ClientPage;
